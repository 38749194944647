"use client";

import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./Arrows";
import "react-horizontal-scrolling-menu/dist/styles.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import { BlurImage } from "@/components/BlueImage";
import "./style.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
const HorizontalScrollSection = ({
  images,
  title,
  onClick,
}: {
  images: Array<string>;
  title: string;
  onClick: (index: number) => void;
}) => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  return (
    <section className="my-10 flex h-48">
      <div onMouseEnter={disableScroll} onMouseLeave={enableScroll} className="hidden sm:block">
        <ScrollMenu
          onWheel={onWheel}
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          scrollContainerClassName="md:max-w-7xl"
        >
          {images.map((image: string, index: number) => (
            <BlurImage
              src={image}
              alt={`Screenshot ${index + 1} of ${title}`}
              height={200}
              width={200}
              imageClassName="max-h-[150px] min-h-[150px] min-w-[150px] mx-2 cursor-pointer"
              onClick={() => onClick(index)}
            />
          ))}
        </ScrollMenu>
      </div>
    </section>
  );
};

export default HorizontalScrollSection;
