"use client";

import { useFormStatus } from "react-dom";
import React from "react";
import classNames from "classnames";

export default function FormComponent({
  formAction,
  title = "Approve",
  className = "btn-secondary",
  disabled = false,
}: {
  formAction: () => Promise<void>;
  title?: string;
  className?: string;
  disabled?: boolean;
}) {
  const { pending } = useFormStatus();
  return (
    <button
      className={classNames("btn min-w-24", className)}
      type="submit"
      disabled={disabled || pending}
      formAction={formAction}
    >
      {pending && <span className="loading loading-spinner loading-md"></span>}
      {!pending && title}
    </button>
  );
}
