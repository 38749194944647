"use client";

import { MdContentCopy, MdShare } from "react-icons/md";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

interface Props {
  url: string;
}
const ShareURLButton: React.FC<Props> = ({ url }) => {
  return (
    <>
      <button
        className="btn btn-sm btn-circle btn-ghost"
        type="button"
        // @ts-ignore
        onClick={() => document.getElementById("share_button_url")?.showModal()}
      >
        <MdShare size={20} />
      </button>

      <dialog id="share_button_url" className="modal">
        <div className="modal-box flex flex-col gap-4">
          <div className="flex items-center gap-2 text-xl font-bold">
            <MdShare size={20} />
            Share
          </div>
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          <div className="mb-3 flex items-center rounded-md bg-slate-600 p-3">
            <div>
              <span className="grow">{url}</span>
            </div>
            <div>
              <button
                onClick={(e) => {
                  navigator.clipboard.writeText(url);
                  e.stopPropagation();
                }}
                className="flex h-8 min-h-8 w-8 min-w-8 items-center justify-center self-end rounded-full bg-black"
              >
                <MdContentCopy size={16} />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-3">
            <EmailShareButton url={url}>
              <EmailIcon round size={32} />
            </EmailShareButton>
            <FacebookShareButton url={url}>
              <FacebookIcon round size={32} />
            </FacebookShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon round size={32} />
            </LinkedinShareButton>
            <WhatsappShareButton url={url}>
              <WhatsappIcon round size={32} />
            </WhatsappShareButton>
            <TelegramShareButton url={url}>
              <TelegramIcon round size={32} />
            </TelegramShareButton>
            <RedditShareButton url={url}>
              <RedditIcon round size={32} />
            </RedditShareButton>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default ShareURLButton;
