"use client";

import { Tool } from "@prisma/client";
import React from "react";
import { approveTool } from "@/actions/tools";
import toast from "react-hot-toast";
import Alert, { AlertType } from "@/components/Alerts";
import { useRouter } from "next/navigation";
import FormComponent from "@/components/FormComponent";

const ApprovalForm = ({ tool }: { tool: Tool }) => {
  const router = useRouter();
  return (
    <form className="mx-5">
      <FormComponent
        formAction={async () => {
          const result = await approveTool(tool.id);
          if (!result) {
            // Sentry error
            toast.custom(<Alert type={AlertType.Error} content="Something went wrong" />);
            return;
          }
          if (result.error) {
            // @ts-ignore
            const error = result.error?._errors ? result.error?._errors?.join(", ") : result.error?.message;
            toast.custom(<Alert type={AlertType.Error} content={error} />);
            return;
          }
          toast.custom(<Alert type={AlertType.Success} content="Tool has been approved" />);

          router.replace("/");
        }}
      />
    </form>
  );
};

export default ApprovalForm;
