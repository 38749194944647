import(/* webpackMode: "eager", webpackExports: ["BlurImage"] */ "/vercel/path0/apps/web/src/components/BlueImage/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/BookmarkButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/ImageCarosel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AspectRatio"] */ "/vercel/path0/apps/web/src/components/MDX/aspect-ratio.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/MDX/pre.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/Posts/ImageZoom.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/Posts/TableOfContents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/ShareURL/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/Tools/ApprovalForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Files","File","Folder"] */ "/vercel/path0/node_modules/fumadocs-ui/dist/components/files.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/link.js");
