"use client";

import * as React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useEffect, useRef } from "react";
import HorizontalScrollSection from "@/components/HorizontalScrollSection";

const ImageCarousel = ({ images, title }: { images: Array<string>; title: string }) => {
  const galleryRef = useRef<ImageGallery>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [showFullScreen, setShowFullScreen] = React.useState(false);

  useEffect(() => {
    if (galleryRef.current) {
      if (showFullScreen) {
        galleryRef.current.slideToIndex(selectedIndex);
        // galleryRef.current.fullScreen();
      }
    }
  }, [selectedIndex, showFullScreen]);

  return (
    <>
      <HorizontalScrollSection
        images={images}
        title={title}
        onClick={(index: number) => {
          console.log("Clicked");
          setShowFullScreen(true);
          setSelectedIndex(index);
        }}
      />

      <dialog id="ai-tools-image-slider_1" className={`modal ${showFullScreen ? "modal-open" : ""}`}>
        <div className="modal-box w-full max-w-7xl">
          <ImageGallery
            ref={galleryRef}
            infinite
            showThumbnails={false}
            showPlayButton={false}
            useBrowserFullscreen
            showFullscreenButton={false}
            items={images?.map((image) => ({
              original: image,
              thumbnail: image,
            }))}
          />
        </div>
        <form method="dialog" className="modal-backdrop">
          <button
            onClick={() => {
              setShowFullScreen(false);
            }}
          >
            close
          </button>
        </form>
      </dialog>
    </>
  );
};

export default ImageCarousel;
